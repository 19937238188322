/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { Controller, useFieldArray } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { sortIngredientsFollowKey } from "../../../utilities/index";

function Substitute(props) {
  const {
    substitutes,
    ingredients,
    selectedIngredients,
    control,
    register,
    errors,
    menuIndex,
    ingredientIndex,
    getValues,
    setError,
    restaurantId,
    updateMenuItemOnIngredientsChange,
    SelectedSubstitutesToCheckForIngredient
  } = props;

  const [selectedSubstitutes, setSelectedSubstitutes] = useState(substitutes);
  const [listMenu, setListMenu] = useState([]);
  const { fields, remove } = useFieldArray({
    name: `section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes`,
    control
  });

  //Function to extract allergens of the substitute from backend response
  const ingredientAllergenList = (allergens) => {
    return allergens
      ?.filter((i) =>
        ["Allergens", "FODMAP Diets", "Diets"].includes(
          i.allergens_category?.category_name
        )
      )
      .map((a) => a.allergens_name);
  };

  //Add new substitute
  const addNewSubstitute = (index, substituteIndex) => {
    const values = getValues();
    const appendedSubstitute =
      values.section_items[menuIndex].item_ingredients[index].substitutes[
        substituteIndex
      ];

    if (appendedSubstitute && Object.keys(appendedSubstitute).length === 0) {
      setError(
        `section_items[${menuIndex}].item_ingredients[${index}].substitutes[${substituteIndex}]`,
        {
          type: "focus",
          message: "Substitute is a required field"
        },
        { shouldFocus: true }
      );
      return;
    }

    const substituteChangedIngredient =
      getValues().section_items?.[menuIndex].item_ingredients[index];
    substituteChangedIngredient.substitutes.push(appendedSubstitute);
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;

    ingredientList[index] = substituteChangedIngredient;
    updateMenuItemOnIngredientsChange(menuIndex, ingredientList);
    SelectedSubstitutesToCheckForIngredient(
      ingredientList.map((ing) => ing.substitutes)
    );
  };

  //Function to remove the new substitute inputs which are not actually saved in the database
  const cancelNewSubstitute = (substituteIndex) => {
    remove(substituteIndex);
  };

  //Function to remove substitute from ingredient
  const handleRemoveSubstitute = (index, substituteIndex) => {
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;
    const substitutesList =
      getValues().section_items?.[menuIndex]?.item_ingredients[index]
        ?.substitutes;
    substitutesList.splice(substituteIndex, 1);
    ingredientList[index].substitutes = substitutesList;
    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id
    );
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
    SelectedSubstitutesToCheckForIngredient(
      ingredientsToBeSaved.map((ing) => ing.substitutes)
    );
  };

  const handleSubstituteOnChangeEvent = (
    options,
    substituteIndex,
    substitute
  ) => {
    const currentSubstitutes = selectedSubstitutes;
    if (currentSubstitutes?.[substituteIndex]) {
      currentSubstitutes[substituteIndex] = options[0];
    } else currentSubstitutes.push(options[0]);

    setSelectedSubstitutes(currentSubstitutes);
    const isHideToast = true;
    if (substitute.substitute_id && options.length !== 0) {
      updateMenuItemOnIngredientsChange(
        menuIndex,
        getValues().section_items?.[menuIndex].item_ingredients?.filter(
          (ing) => ing.ingredient_id
        ),
        isHideToast
      );
    }
    addNewSubstitute(ingredientIndex, substituteIndex);
  };

  //Function change reorder list menu
  const handleSearch = (query, index) => {
    const keySearch = query.toLowerCase();
    const menus = [...filterOption(ingredients, index)].filter(
      (menu) => !menu.restaurant_id || menu.restaurant_id == restaurantId
    );

    const listSortMenu = menus.filter((ing) =>
      ing?.name?.toLowerCase().includes(keySearch)
    );

    setListMenu(sortIngredientsFollowKey(listSortMenu, keySearch));
  };

  const handleClick = (index) => {
    const menus = filterOption(ingredients, index);
    setListMenu(
      menus.filter(
        (menu) => !menu.restaurant_id || menu.restaurant_id == restaurantId
      )
    );
  };

  const convertIgredientName = (ingredient) => {
    if (ingredient.display_name) {
      return `${ingredient.display_name} (${ingredient.name})`;
    }

    return ingredient.name;
  };

  const filterOption = (list, index) => {
    return [...list].filter(
      (ing) =>
        ing?.name !==
          selectedIngredients?.[ingredientIndex]?.substitute_ingredient?.name &&
        !selectedSubstitutes
          ?.map((sub) => sub?.name)
          .filter(
            (name) =>
              !(
                selectedSubstitutes[index] &&
                selectedSubstitutes[index]?.name?.includes(name)
              )
          )
          .includes(ing?.name)
    );
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <>
          {fields?.map((substitute, substituteIndex) => (
            <div className="ps-4" key={substitute.id} id={substitute.id}>
              <div className="substitute">
                <hr className="tree-line" />
                <Form.Group>
                  <div className="substitute-box">
                    <Controller
                      control={control}
                      name={`section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`}
                      {...register(
                        `section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`
                      )}
                      render={({
                        field,
                        fieldState: { isInvalid, isTouched, isDirty, error }
                      }) => {
                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <InputGroup className="position-relative">
                                <Typeahead
                                  key={substitute.id}
                                  placeholder="Select Substitute"
                                  id={`section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`}
                                  labelKey="name"
                                  multiple={false}
                                  onChange={(options) => {
                                    field.onChange(options);
                                    handleSubstituteOnChangeEvent(
                                      options,
                                      substituteIndex,
                                      substitute
                                    );
                                  }}
                                  onInputChange={(query) =>
                                    handleSearch(query, substituteIndex)
                                  }
                                  defaultInputValue={field?.value?.name}
                                  onFocus={() => handleClick(substituteIndex)}
                                  options={listMenu}
                                  renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps}>
                                      {results.map((result, index) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              color: result.is_official
                                                ? "#0747A6"
                                                : "black",
                                              fontWeight: result.is_official
                                                ? "bold"
                                                : "400"
                                            }}
                                            option={result}
                                            position={index}
                                          >
                                            {convertIgredientName(result)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>
                                  )}
                                />
                                <div className="edit-icon">
                                  <BiEditAlt />
                                </div>
                              </InputGroup>
                              <Button
                                className="d-flex align-items-center ms-2"
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  substitute?.substitute_id
                                    ? handleRemoveSubstitute(
                                        ingredientIndex,
                                        substituteIndex
                                      )
                                    : cancelNewSubstitute(substituteIndex);
                                }}
                              >
                                <RiDeleteBinLine className="me-2" />
                                Delete
                              </Button>
                            </div>
                            {(isInvalid || isTouched || isDirty || error) && (
                              <div className="invalid-custom-feedback">
                                {
                                  errors?.section_items?.[menuIndex]
                                    ?.item_ingredients?.[ingredientIndex]
                                    ?.substitutes?.[substituteIndex]?.message
                                }
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                    <div className="substitute-allergens">
                      {substitute?.allergens && (
                        <p>
                          {ingredientAllergenList(substitute?.allergens).join(
                            ", "
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
export default forwardRef(Substitute);
