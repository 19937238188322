import React from "react";
import { Modal, ModalBody, Button, ModalFooter } from "react-bootstrap";

const ConfirmAction = ({ show, confirmationText, confirm, handleClose, confirmButtonText, cancelButtonText , styleClass, confirmTitle, buttonVariant}) => {
  return (
    <Modal show={show} onHide={handleClose} className={styleClass}>
      <Modal.Header closeButton>
        <Modal.Title>{confirmTitle}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div>
          <div>{confirmationText}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div>
          <Button variant="secondary me-2" onClick={handleClose}>
            {cancelButtonText}
          </Button>
          <Button variant={buttonVariant} onClick={confirm}>
            {confirmButtonText}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmAction;
