import React from "react";
import "../styles/style.scss";
import Routes from "../Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "tippy.js/dist/tippy.css";

function App() {
  return <Routes />;
}

export default App;
