import React from "react";
import { Route, Routes } from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent";
import Main from "./components/Layout/Main";
import NotFoundPage from "./components/NotFoundPage";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./features/login/Login";
import RestaurantDetailsView from "./features/restaurantDetails/RestaurantDetailsView";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/:id/login" element={<Login />} />
      <Route
        path="/:id/restaurant-details"
        element={<PrivateRoute component={RestaurantDetailsView} />}
      />
      <Route path="/:id" element={<Main />} />
      <Route path="/:id/loader" element={LoadingComponent} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AllRoutes;
