import AppLayout from "./Layout/AppLayout";
import { Navigate, useParams } from "react-router-dom";
import { getToken } from "../utilities/apiHelper";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const sessionDetails = JSON.parse(localStorage.getItem("currentSessionInfo"));
  const isAuthorized = getToken()
    ? sessionDetails?.access_exp * 1000 > Date.now()
      ? true
      : false
    : false;
  const { id } = useParams();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthorized ? (
    <AppLayout>
      <Component {...rest} />
    </AppLayout>
  ) : (
    <Navigate to={`/${id}/login`} />
  );
};

export default PrivateRoute;
