import React from "react";
import { Navbar, Nav, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { logOutUser } from "../../store/auth/authApi";
import { BsPerson } from "react-icons/bs";
import { useParams } from "react-router-dom";

const Header = () => {
  const { id } = useParams();
  const restaurantId = id;
  const getLoggedInUserName = () => {
    const sessionDetails = JSON.parse(localStorage.getItem("currentSessionInfo"));
    return `${sessionDetails?.first_name || ""} ${sessionDetails?.last_name || ""}`;
  };

  return (
    <Navbar bg="light" expand="lg" className="justify-content-end">
      <Nav>
        <Dropdown align="end" as={NavItem}>
          <Dropdown.Toggle as={NavLink}>
            <div className="user-photo">
              <BsPerson />
            </div>
            <span className="mr-2">{getLoggedInUserName()}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => logOutUser(restaurantId)}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

export default Header;
