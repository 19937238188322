import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LoadingState } from "../../utilities/constants";

import { loginUserApi } from "./authApi";

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (payload) => {
    try {
      const data = payload;
      const response = await loginUserApi(data);
      return response;
    } catch (error) {
      throw new Error("Invalid credentials, please try again");
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "login/getUserInfo",
  async (payload) => {
    try {
      const { userId, data } = payload;
      const response = await loginUserApi(userId, data);
      return response;
    } catch (error) {
      toast.error("Failed to get user information");
      throw error;
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    userInfo: {},
    loggedInUserStatus: LoadingState.idle,
    error: {}
  },
  reducers: {},
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loggedInUserStatus = LoadingState.loading;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loggedInUserStatus = LoadingState.succeeded;
      state.userInfo = action.payload;
    },
    [loginUser.failed]: (state, action) => {
      state.loggedInUserStatus = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default loginSlice.reducer;
