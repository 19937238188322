import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function NotFoundPage() {
  return (
    <Container className="vh-100 d-flex align-items-center">
      <Row className="w-100">
        <Col md="6" className="d-flex align-items-center">
          <div className="notfound-message">
            <h1>Page not found</h1>
            <p>
              Sorry, the page you are looking for doesn't exist or <br />
              has been moved.
            </p>
          </div>
        </Col>
        <Col md="6">
          <h1 className="notfound-error">404</h1>
        </Col>
      </Row>
    </Container>
  );
}
