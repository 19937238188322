import { get, patch, patchMultiData, put } from "../../utilities/apiHelper";

export const fetchCategoriesApi = async () => {
  try {
    const result = await get("/restaurant/v10/spa/restaurant/categories/", true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFeaturesApi = async () => {
  try {
    const result = await get("/restaurant/v10/spa/features/", true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUsersApi = async () => {
  try {
    const result = await get("/restaurant/v10/spa/users/", true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVenueApi = async (data) => {
  try {
    const result = await get(`/restaurant/v10/spa/restaurant/${data}`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStatusApi = async () => {
  try {
    const result = await get("/restaurant/v10/spa/status/", true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateVenueApi = async (id, data) => {
  try {
    const result = await put(
      `/restaurant/v10/spa/restaurant/${id}/`,
      data,
      true
    );
    if (!result.data) {
      throw new Error(result.data.message);
    }
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateVenueImageApi = async (data) => {
  try {
    const { restaurantId, payload } = data;
    const result = await patchMultiData(
      `/restaurant/v10/spa/restaurant/image/${restaurantId}/`,
      payload,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeVenueImageApi = async (data) => {
  try {
    const result = await patch(
      `/restaurant/v10/spa/restaurant/delete_image/`,
      data,
      true
    );
    return result.data.data;
  } catch (error) {
    throw error;
  }
};
