import Spinner from "react-bootstrap/Spinner";

const LoadingComponent = () => (
  <div className="loader-canvas">
    <Spinner animation="border" role="status" variant="light">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default LoadingComponent;
