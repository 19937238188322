/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SectionFormSchema } from "./MenuFormValidationSchema";
import {
  Spinner,
  Accordion,
  Button,
  Badge,
  Row,
  Col,
  Card,
  Form,
  useAccordionButton,
  AccordionContext
} from "react-bootstrap";
import { HiOutlinePlusSm } from "react-icons/hi";
import { MdOutlineSaveAlt } from "react-icons/md";
import MenuItems from "./MenuItems";
import {
  addNewSectionApi,
  fetchIngredientsApi,
  fetchSectionsApi,
  menuItemSectionChangeApi,
  removeSectionApi,
  updateSectionApi,
  verifyAllMenuItemInSectionMeApi
} from "../../../store/menu/menuApi";
import { useParams } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import ConfirmAction from "../../../components/ConfirmAction";
import { toast } from "react-toastify";
import noDataImage from "../../../images/no-data.svg";

const Menu = () => {
  const {
    register,
    control,
    reset,
    getValues,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SectionFormSchema),
    defaultValues: {
      sections: []
    },
    mode: "onChange"
  });

  const [loading, setLoading] = useState(true);
  const [loadingIngredients, setLoadingIngredients] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [confirmActionVisibility, setConfirmActionVisibility] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [numberIsVerifiedMenu, setNumberIsVerifiedMenu] = useState(0);
  const [numberIsUnverifiedMenu, setNumberIsUnverifiedMenu] = useState(0);
  const [numberEmptyMenu, setNumberEmptyMenu] = useState(0);
  const { id } = useParams();
  const restaurantId = id;
  const [activeKey, setActiveKey] = useState();
  const { fields, update, remove, replace } = useFieldArray({
    name: "sections",
    control
  });

  const sectionsFieldsRef = React.useRef(fields);

  useEffect(() => {
    if (fields.length) {
      sectionsFieldsRef.current = fields;
    }
  }, [fields]);

  const handleConfirmActionVisibility = (state, index) => {
    setSelectedSectionIndex(index);
    setConfirmActionVisibility(state);
  };

  //Add input for new section
  const addNewSectionInput = () => {
    const fieldsWithNewSection = [
      ...sectionsFieldsRef.current,
      { section_name: "", section_items: [] }
    ];
    sectionsFieldsRef.current = fieldsWithNewSection;
    replace(fieldsWithNewSection);
  };

  //Add new section
  const addNewSection = (index) => {
    const values = getValues();
    if (
      !values.sections[index].section_name ||
      (values.sections[index].section_name &&
        values.sections[index].section_name?.toString().trim().length === 0)
    ) {
      setError(
        `sections[${index}].section_name`,
        { type: "focus", message: "Section Name is a required field" },
        { shouldFocus: true }
      );
      return;
    }
    if (
      values.sections[index].section_name &&
      values.sections[index].section_name?.toString().trim().length !== 0 &&
      values.sections[index].section_name.toLowerCase() ===
        "Uncategorized".toLowerCase()
    ) {
      setError(
        `sections[${index}].section_name`,
        { type: "focus", message: "Please enter different category name." },
        { shouldFocus: true }
      );
      return;
    }
    const sectionData = {
      section_name: values.sections[index].section_name,
      restaurant_id: restaurantId
    };

    addNewSectionApi(sectionData)
      .then((result) => {
        const sections = [...sectionList];
        sections.push({ ...result, section_id: result.id });
        setSectionList(sections);
        reset({
          sections: sections
        });
        toast.success("A new section is created successfully");
      })
      .catch((e) => {
        toast.error("Failed to create a New section");
      });
  };

  // Function to remove the new section inputs which are not actually saved in the database
  const cancelNewSectionCreation = (index) => {
    remove(index);
  };

  // Function to remove section
  const handleRemoveSection = (index) => {
    const values = getValues();
    const section_id = values.sections[index].section_id;
    removeSectionApi(section_id)
      .then((result) => {
        if (result === 204) {
          handleConfirmActionVisibility(false);
          const sections = [...sectionList];
          const itemsToBeMovedToUncategorizedSection = [
            ...values.sections[index].section_items
          ].map((item) => ({ ...item, item_section: null }));
          const uncategorizedSectionItems = sections[0].section_items;
          Array.prototype.push.apply(
            uncategorizedSectionItems,
            itemsToBeMovedToUncategorizedSection
          );
          sections[0].section_items = uncategorizedSectionItems;
          sections.splice(index, 1);
          setSectionList(sections);
          reset({
            sections: sections
          });
          toast.success("Section is removed successfully");
        }
      })
      .catch((e) => {
        handleConfirmActionVisibility(false);
        toast.error("Failed to remove section");
      });
  };

  // Function to update section when menu item change from one section to another section
  const updateMenuItemSection = (sectionIndex, menuIndex, newSection) => {
    const data = {
      section_id: newSection,
      menu_id: { ...sectionList[sectionIndex] }.section_items[menuIndex].item_id
    };
    menuItemSectionChangeApi(data).then((result) => {
      const currentSection = { ...sectionList[sectionIndex] };
      const formattedIngredients = result?.item_ingredients.map((ing) => ({
        ...ing,
        ingredient_id: ing.id,
        substitutes: ing?.substitutes?.map((substitute) => ({
          ...substitute,
          substitute_id: substitute.id
        }))
      }));
      const formattedResult = {
        ...result,
        item_id: result.id,
        item_ingredients: formattedIngredients
      };

      const indexOfItemToBeMoved = currentSection.section_items.findIndex(
        (item) => item.item_id === formattedResult.item_id
      );
      currentSection.section_items.splice(indexOfItemToBeMoved, 1);
      update(sectionIndex, currentSection);

      const newSectionIndex = sectionList.findIndex(
        (e) => e.section_id === Number(newSection)
      );
      const newSectionSection = { ...sectionList[newSectionIndex] };

      newSectionSection.section_items.push(formattedResult);
      update(newSectionIndex, newSectionSection);
      toast.success("Menu Item successfully changed to another section");
    });
  };

  // Function to update section
  const updateSection = (index) => {
    const values = getValues();
    const newValue = values.sections[index].section_name;
    const oldValue = sectionList[index].section_name;

    if (
      !newValue ||
      (newValue && newValue.toString().trim().length === 0) ||
      newValue === oldValue
    ) {
      return;
    }

    const data = {
      section_id: values.sections[index].section_id,
      payload: { section_name: values.sections[index].section_name }
    };

    updateSectionApi(data)
      .then((res) => {
        let sections = sectionList;
        sections[index].section_name = newValue;
        sectionsFieldsRef.current[index].section_name = newValue;
        setSectionList(sections);
        toast.success("Section updated successfully");
      })
      .catch((e) => {
        toast.error("Failed to update the section");
      });
  };

  // Fetch all sections from the backend
  const getSections = async (restaurantId) => {
    setLoading(true);
    fetchSectionsApi(restaurantId)
      .then((response) => {
        let sortedResponse = [];
        //
        response.forEach((menu) => {
          const sortedMenu = {
            ...menu,
            section_items: menu.section_items,
            numberIsVerified: 0
          };
          menu.section_items.map((section, index) => {
            if (section.is_verified) sortedMenu.numberIsVerified++;
            return section;
          });
          sortedResponse.push(sortedMenu);
        });
        //
        setSectionList(sortedResponse);
        countIsVerified(sortedResponse);
        console.log("sortedResponse", sortedResponse);
        reset({
          sections: sortedResponse
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Fetch all ingredients which are used as Typeahead options in the ingredient and substitute part
  const getIngredients = async () => {
    setLoadingIngredients(true);
    fetchIngredientsApi()
      .then((response) => {
        setIngredients(response);
        setLoadingIngredients(false);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => {
        setLoadingIngredients(false);
      });
  };

  useEffect(() => {
    getSections(restaurantId);
    getIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Customize function to toggle the section accordion
  const CustomSectionToggle = ({ children, eventKey, callback, sectionId }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (!activeEventKey && activeEventKey !== 0) {
        setActiveKey(null);
      }
    });

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <>
        {sectionId && (
          <Button
            type="button"
            aria-expanded={isCurrentEventKey}
            className={`accordion-button me-3 ${
              !isCurrentEventKey ? "collapsed" : ""
            }`}
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        )}
      </>
    );
  };

  const updateSectionOnMenuItemAdditionAndDelete = (
    sectionIndex,
    menuItems
  ) => {
    const sectionData = sectionList;

    sectionData[sectionIndex].section_items = menuItems;
    let numberIsVerified = 0;
    sectionData[sectionIndex].section_items.map((section, index) => {
      if (section.is_verified) numberIsVerified++;
      return section;
    });
    sectionData[sectionIndex].numberIsVerified = numberIsVerified;
    const newSectionSection = { ...sectionList[sectionIndex] };
    update(sectionIndex, newSectionSection);
    setSectionList(sectionData);
    countIsVerified(sectionData);
  };

  //function to count number IsVerified of menu
  const countIsVerified = (list) => {
    let numberIsVerifiedSection = 0;
    let numberIsUnverifiedSection = 0;
    let numberEmpty = 0;
    // eslint-disable-next-line array-callback-return
    list.map((section) => {
      if (!section.section_items.length) numberEmpty++;
      section.section_items.forEach((item) => {
        if (item.is_verified) {
          numberIsVerifiedSection++;
        } else {
          numberIsUnverifiedSection++;
        }
      });
    });
    setNumberIsVerifiedMenu(numberIsVerifiedSection);
    setNumberIsUnverifiedMenu(numberIsUnverifiedSection);
    setNumberEmptyMenu(numberEmpty);
  };

  //function to verify all menu
  const verifyAllMenu = () => {
    const sectionData = sectionList;
    sectionData.map((menu) => {
      menu.numberIsVerified = menu.section_items.length;
      menu.section_items.map((section) => {
        section.is_verified = true;
        return section;
      });
      return menu;
    });
    setLoadingButton(true);
    verifyAllMenuItemInSectionMeApi(restaurantId)
      .then((result) => {
        setSectionList(sectionData);
        countIsVerified(sectionData);
        replace(sectionData);
        setLoadingButton(false);
        toast.success("Menu item updated successfully");
      })
      .catch((e) => {
        setLoadingButton(false);
        toast.error("Failed to update the Menu item");
      });
  };

  //save active Accordion Key
  const saveActiveKey = (key) => {
    setActiveKey(key);
  };
  return (
    <>
      {(loading || loadingIngredients) && (
        <Spinner className="loader" animation="grow" />
      )}
      {!loading && !loadingIngredients && (
        <>
          {fields.length === 0 && (
            <div className="no-data">
              <img src={noDataImage} className="img-fluid" alt="no data" />
              <h6 v-else>Menu details not available for this restaurant</h6>
              <div className="add-new-section">
                <Button
                  type="button"
                  variant="outline-primary"
                  className="add-new-button d-flex align-items-center"
                  onClick={addNewSectionInput}
                >
                  <span className="me-1 d-flex align-items-center justify-content-center">
                    <HiOutlinePlusSm />
                  </span>
                  <span>Section</span>
                </Button>
              </div>
            </div>
          )}
          {fields.length > 0 && (
            <div className="px-4 pt-2">
              <div className="d-flex align-items-center mb-2">
                <Button
                  variant="outline-primary"
                  disabled={
                    fields.length - numberEmptyMenu - numberIsVerifiedMenu ===
                      0 || loadingButton
                  }
                  onClick={verifyAllMenu}
                >
                  Verify All
                </Button>

                <div className="mx-4">
                  <span>
                    {numberIsVerifiedMenu} Verified / {numberIsUnverifiedMenu}{" "}
                    Unverified
                  </span>

                  {loadingButton && (
                    <span style={{ marginLeft: "5px" }}>
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                      ></Spinner>
                    </span>
                  )}
                </div>
              </div>
              <Accordion className="accordion-main">
                {fields.map((section, index) => (
                  <Card
                    key={section.id}
                    className={
                      section.section_name === "Uncategorized"
                        ? "uncategorized-setion"
                        : null
                    }
                  >
                    <Card.Header>
                      <Form>
                        <Row>
                          <Col lg="5" className="d-flex align-items-center">
                            <CustomSectionToggle
                              eventKey={index}
                              sectionId={section.id}
                            />
                            <Form.Group className="w-100">
                              <Form.Control
                                readOnly={
                                  section.section_name === "Uncategorized"
                                    ? true
                                    : false
                                }
                                name={`sections[${index}].section_name`}
                                {...register(`sections[${index}].section_name`)}
                                isInvalid={
                                  !!errors.sections &&
                                  errors.sections[index]?.section_name
                                }
                                onBlur={() => {
                                  if (section.section_id) {
                                    updateSection(index);
                                  }
                                }}
                              />
                              <Form.Control.Feedback
                                id="sectionNameError"
                                type="invalid"
                              >
                                {!!errors.sections &&
                                  errors.sections[index]?.section_name?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Badge bg="info" className="ms-2 number-indicator">
                              {section.section_items.length}
                            </Badge>
                          </Col>
                          <Col
                            lg="7"
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <span>
                                {section.numberIsVerified} Verified /{" "}
                                {section.section_items.length -
                                  section.numberIsVerified || 0}{" "}
                                Unverified
                              </span>
                            </div>
                            {!section.section_id && section.section_id !== 0 && (
                              <Button
                                variant="outline-secondary"
                                className="d-flex align-items-center me-1"
                                size="sm"
                                onClick={() => addNewSection(index)}
                              >
                                <MdOutlineSaveAlt className="me-2" />
                                Save
                              </Button>
                            )}
                            {section.section_id !== 0 && (
                              <Button
                                className="d-flex align-items-center"
                                variant="outline-danger"
                                size="sm"
                                onClick={() =>
                                  section.section_id
                                    ? handleConfirmActionVisibility(true, index)
                                    : cancelNewSectionCreation(index)
                                }
                              >
                                <RiDeleteBinLine className="me-2" />
                                Delete
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Card.Header>
                    <Accordion.Collapse className="ps-5" eventKey={index}>
                      <MenuItems
                        sectionIndex={index}
                        sections={sectionList.map((s) => ({
                          section_id: s.section_id,
                          section_name: s.section_name
                        }))}
                        section={section}
                        updateMenuItemSection={updateMenuItemSection}
                        ingredients={ingredients}
                        updateSectionOnMenuItemAdditionAndDelete={
                          updateSectionOnMenuItemAdditionAndDelete
                        }
                        sectionsFieldsRef={sectionsFieldsRef}
                        activeKey={activeKey}
                        saveActiveKey={saveActiveKey}
                      />
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
              <Row className="add-new-section mt-3">
                <Col sm={5} className="d-flex align-items-center">
                  <hr />
                </Col>
                <Col sm={2} className="d-flex justify-content-center">
                  <Button
                    type="button"
                    variant="outline-primary"
                    className="add-new-button"
                    onClick={addNewSectionInput}
                  >
                    <HiOutlinePlusSm />
                    Section
                  </Button>
                </Col>
                <Col sm={5} className="d-flex align-items-center">
                  <hr />
                </Col>
              </Row>
            </div>
          )}
          <ConfirmAction
            show={confirmActionVisibility}
            confirmationText={
              "If this category is deleted, all related Menu Items will be moved to the Uncategorized section. Are you sure you want to delete this category?"
            }
            confirm={() => handleRemoveSection(selectedSectionIndex)}
            confirmButtonText={"Delete"}
            cancelButtonText={"Cancel"}
            confirmTitle={"Are you sure you want to delete?"}
            buttonVariant={"danger"}
            handleClose={() => handleConfirmActionVisibility(false)}
          />
        </>
      )}
    </>
  );
};

export default Menu;
