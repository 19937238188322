import React from "react";
import { Tab, Tabs, Container } from "react-bootstrap";
import Menu from "./menu/Menu";
import Venue from "./venue/Venue";

const RestaurantDetailsView = () => {
  return (
    <Container className="mt-5">
      <div className="tab-wrapper mb-5">
        <Tabs defaultActiveKey="venue" id="uncontrolled-tab-example">
          <Tab eventKey="venue" title="Venue" key="venue">
            <Venue />
          </Tab>
          <Tab eventKey="menu" title="Menu" key="Menu">
            <Menu />
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default RestaurantDetailsView;
