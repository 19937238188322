import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./auth/authSlice";
import venueReducer from "./venue/venueSlice";
import menuReducer from "./menu/menuSlice";

const rootReducer = combineReducers({
  login: loginReducer,
  venue: venueReducer,
  menu: menuReducer
});

export default rootReducer;
