import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LoadingState } from "../../utilities/constants";

import { fetchIngredientsApi, fetchSectionsApi } from "./menuApi";

export const fetchSections = createAsyncThunk(
  "menu/fetchSections",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchSectionsApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch sections");
      throw error;
    }
  }
);

export const fetchIngredients = createAsyncThunk(
  "menu/fetchIngredients",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchIngredientsApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch ingredients");
      throw error;
    }
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    sections: [],
    ingredients: [],
    fetchSectionsStatus: LoadingState.idle,
    fetchIngredientsStatus: LoadingState.idle,
    error: {}
  },
  reducers: {},
  extraReducers: {
    [fetchSections.pending]: (state) => {
      state.fetchSectionsStatus = LoadingState.loading;
    },
    [fetchSections.fulfilled]: (state, action) => {
      state.fetchSectionsStatus = LoadingState.succeeded;
      state.sections = action.payload;
    },
    [fetchSections.failed]: (state, action) => {
      state.fetchSectionsStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchIngredients.pending]: (state) => {
      state.fetchIngredientsStatus = LoadingState.loading;
    },
    [fetchIngredients.fulfilled]: (state, action) => {
      state.fetchIngredientsStatus = LoadingState.succeeded;
      state.ingredients = action.payload;
    },
    [fetchIngredients.failed]: (state, action) => {
      state.fetchIngredientsStatus = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default menuSlice.reducer;
