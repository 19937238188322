import { post } from "../../utilities/apiHelper";
import history from "../../utilities/history";

export const loginUserApi = async (requestData) => {
  try {
    const result = await post("/auth/v10/user/login/", requestData, false);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const logOutUser = async (restaurantId) => {
  localStorage.removeItem("currentSessionInfo");
  history.push(`/${restaurantId}/login`);
};

export const getUserInfoApi = async (userId, requestData) => {
  try {
    const result = await post(`/auth/v10/user/${userId}/`, requestData, false);
    if (!result.data) {
      throw new Error(result.data.message);
    }
    return result.data;
  } catch (error) {
    throw error;
  }
};
