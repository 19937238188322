import Autocomplete from "react-google-autocomplete";
import { appConfigurations } from "../utilities/config";

const GoogleAddressAutoCompleteWidget = ({ field }) => {
  return (
    <div>
      <Autocomplete
        apiKey={appConfigurations.GOOGLE_API_KEY}
        className="form-control"
        onPlaceSelected={(place) =>{
          field.onChange({
            address_line: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          })
        }
        }
        options={{
          types: ["address"],
          // componentRestrictions: { country: "au" },
        }}
        
        defaultValue={field?.value?.address_line}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    </div>
  );
};

export default GoogleAddressAutoCompleteWidget;
