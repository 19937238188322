import {
  get,
  patch,
  patchMultiData,
  post,
  put,
  remove
} from "../../utilities/apiHelper";

export const fetchSectionsApi = async (restaurantId) => {
  try {
    const result = await get(
      `/restaurant/v10/spa/menu/sections/${restaurantId}/`,
      true
    );
    if (!result.data) {
      throw new Error(result.data.message);
    }
    const respData = result?.data?.data
      ? result?.data?.data?.map((s) => ({
          ...s,
          section_id: s.id,
          section_items: s.section_items?.map((item) => ({
            ...item,
            item_id: item.id,
            item_ingredients: item.item_ingredients?.map((ingredient) => ({
              ...ingredient,
              ingredient_id: ingredient.id,
              substitutes: ingredient?.substitutes?.map((substitute) => ({
                ...substitute,
                substitute_id: substitute.id
              }))
            }))
          }))
        }))
      : [];
    return respData;
  } catch (error) {
    throw error;
  }
};

export const fetchIngredientsApi = async (page) => {
  try {
    const result = await get(`/restaurant/v10/spa/menu/ingrediants/`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const addNewSectionApi = async (data) => {
  try {
    const result = await post(`/restaurant/v10/spa/menu/sections/`, data, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const addNewMenuItemApi = async (data) => {
  try {
    const { restaurant_id, payload } = data;
    const result = await post(
      `/restaurant/v10/spa/menu/items/?restaurant_id=${restaurant_id}`,
      payload,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateSectionApi = async (data) => {
  try {
    const { section_id, payload } = data;
    const result = await put(
      `/restaurant/v10/spa/menu/sections/${section_id}/`,
      payload,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const removeMenuItemApi = async (item_id) => {
  try {
    const result = await remove(
      `/restaurant/v10/spa/menu/items/${item_id}/`,
      true
    );
    return result.status;
  } catch (error) {
    throw error;
  }
};
export const removeSectionApi = async (section_id) => {
  try {
    const result = await remove(
      `/restaurant/v10/spa/menu/sections/${section_id}/`,
      true
    );
    return result.status;
  } catch (error) {
    throw error;
  }
};

export const updateMenuItemApi = async (data) => {
  try {
    const { menu_item_id, payload } = data;
    const result = await put(
      `/restaurant/v10/spa/menu/items/${menu_item_id}/`,
      payload,
      true
    );
    const formattedIngredients = result?.data?.data?.item_ingredients.map(
      (ing) => ({
        ...ing,
        ingredient_id: ing.id,
        substitutes: ing?.substitutes?.map((substitute) => ({
          ...substitute,
          substitute_id: substitute.id
        }))
      })
    );
    if (!result.data) throw new Error(result.data.message);
    const responseData = result?.data?.data ? result?.data?.data : {};
    return { ...responseData, item_ingredients: formattedIngredients };
  } catch (error) {
    throw error;
  }
};

export const updateMenuItemImageApi = async (data) => {
  try {
    const { menu_item_id, payload } = data;
    const result = await patchMultiData(
      `/restaurant/v10/spa/menu/items/${menu_item_id}/`,
      payload,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const menuItemSectionChangeApi = async (data) => {
  try {
    const result = await patch(
      `/restaurant/v10/spa/menu/items/change_section/`,
      data,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const removeMenuItemImageApi = async (data) => {
  try {
    const result = await patch(
      `/restaurant/v10/spa/menu/items/delete_image/`,
      data,
      true
    );
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

// API to verify an item:
export const verifyAnMenuItemInSectionMeApi = async (menuId , isVerify) => {
  try {
    const result = await patch(
      `/restaurant/v10/spa/menu/items/${menuId}/change_verify/?verified=${isVerify}`,
      null,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

// API to verify all item:
export const verifyAllMenuItemInSectionMeApi = async (restaurantId) => {
  try {
    const result = await patch(
      `/restaurant/v10/spa/restaurant/${restaurantId}/verify_all/`,
      null,
      true
    );
    if (!result.data) throw new Error(result.data.message);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};