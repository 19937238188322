import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LoadingState } from "../../utilities/constants";

import {
  fetchCategoriesApi,
  fetchFeaturesApi,
  fetchStatusApi,
  fetchUsersApi,
  fetchVenueApi,
  updateVenueApi
} from "./venueApi";

export const fetchCategories = createAsyncThunk(
  "venue/fetchCategories",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchCategoriesApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch categories");
      throw error;
    }
  }
);

export const fetchFeatures = createAsyncThunk(
  "venue/fetchFeatures",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchFeaturesApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch features");
      throw error;
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "venue/fetchUsers",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchUsersApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch users");
      throw error;
    }
  }
);

export const fetchVenue = createAsyncThunk(
  "venue/fetchVenue",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchVenueApi(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchStatus = createAsyncThunk(
  "venue/fetchStatus",
  async (payload) => {
    try {
      const data = payload;
      const response = await fetchStatusApi(data);
      return response;
    } catch (error) {
      toast.error("Failed to fetch status");
      throw error;
    }
  }
);

export const updateVenue = createAsyncThunk(
  "venue/updateVenue",
  async (payload, { dispatch }) => {
    try {
      const { restaurantId, venueData } = payload;
      const response = await updateVenueApi(restaurantId, venueData);
      if (response) {
        await dispatch(fetchVenue(restaurantId));
      }
      return response;
    } catch (error) {
      toast.error("Failed to update venue details");
      throw error;
    }
  }
);

const venueSlice = createSlice({
  name: "venue",
  initialState: {
    categories: [],
    features: [],
    users: [],
    venue: {},
    status: [],
    fetchCategoriesStatus: LoadingState.idle,
    fetchFeaturesStatus: LoadingState.idle,
    fetchUsersStatus: LoadingState.idle,
    fetchVenueStatus: LoadingState.idle,
    fetchStatusStatus: LoadingState.idle,
    updateVenueStatus: LoadingState.idle,
    error: {}
  },
  reducers: {},
  extraReducers: {
    [fetchCategories.pending]: (state) => {
      state.fetchCategoriesStatus = LoadingState.loading;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.fetchCategoriesStatus = LoadingState.succeeded;
      state.categories = action.payload;
    },
    [fetchCategories.rejected]: (state, action) => {
      state.fetchCategoriesStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchFeatures.pending]: (state) => {
      state.fetchFeaturesStatus = LoadingState.loading;
    },
    [fetchFeatures.fulfilled]: (state, action) => {
      state.fetchFeaturesStatus = LoadingState.succeeded;
      state.features = action.payload;
    },
    [fetchFeatures.rejected]: (state, action) => {
      state.fetchFeaturesStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchUsers.pending]: (state) => {
      state.fetchUsersStatus = LoadingState.loading;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.fetchUsersStatus = LoadingState.succeeded;
      state.users = action.payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.fetchUsersStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchVenue.pending]: (state) => {
      state.fetchVenueStatus = LoadingState.loading;
    },
    [fetchVenue.fulfilled]: (state, action) => {
      state.fetchVenueStatus = LoadingState.succeeded;
      state.venue = action.payload;
    },
    [fetchVenue.rejected]: (state, action) => {
      state.fetchVenueStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchStatus.pending]: (state) => {
      state.fetchStatusStatus = LoadingState.loading;
    },
    [fetchStatus.fulfilled]: (state, action) => {
      state.fetchStatusStatus = LoadingState.succeeded;
      state.status = action.payload;
    },
    [fetchStatus.rejected]: (state, action) => {
      state.fetchStatusStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [updateVenue.pending]: (state) => {
      state.updateVenueStatus = LoadingState.loading;
    },
    [updateVenue.fulfilled]: (state, action) => {
      state.updateVenueStatus = LoadingState.succeeded;
    },
    [updateVenue.rejected]: (state, action) => {
      state.updateVenueStatus = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default venueSlice.reducer;
