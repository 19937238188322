export const sortSectionItemsOfMenu = (menuList) => {
  const result = menuList.sort((currentItem, nextItem) =>
    currentItem.item_name.localeCompare(nextItem.item_name)
  );
  return result;
};

export const sortIngredients = (ingredientList) => {
  const result = ingredientList.sort((currentItem, nextItem) =>
    currentItem.substitute_ingredient.name.localeCompare(
      nextItem.substitute_ingredient.name
    )
  );
  return result;
};

export const sortSubstitutes = (substitutesList) => {
  const result = substitutesList.sort((currentItem, nextItem) =>
    currentItem.name.localeCompare(nextItem.name)
  );
  return result;
};

export const sortIngredientsFollowKey = (list, keySearch) => {
  const key = keySearch?.toLowerCase();
  const listSortMenu = [...list].sort((a, b) => a.name?.toLowerCase().indexOf(key) - b.name?.toLowerCase().indexOf(key));
  return listSortMenu;
};
