import * as yup from "yup";

export const VenueFormSchema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  address: yup.object().nullable(),
  description: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup.string().email("Email must be a valid email").nullable(),
  website: yup.string().url("Website must be a valid URL").nullable(),
  category: yup.array(),
  status: yup.number(),
  user_id: yup.array().nullable(),
  is_active: yup.boolean(),
  send_push_notification: yup.string()
});
