import React from "react";
import { ToastContainer } from 'react-toastify';
import Header from "./Header";

const AppLayout = ({children}) => {
  return (
    <div className="page-layout">
      <Header />
      <div className="page-layout-wrapper">{children}</div>
      <ToastContainer toastStyle={{ fontSize: "13px"}}  />
    </div>
  );
};

export default AppLayout;
