import React, { useEffect, useState } from "react";
import { Col, Row, Button, InputGroup, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { LoadingState } from "../../utilities/constants";
import { loginUser } from "../../store/auth/authSlice";
import { Container, Form, Spinner } from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required")
});

const Login = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const restaurantId = id;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange"
  });

  const { userInfo, loggedInUserStatus } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loggedInUserStatus === LoadingState.succeeded) {
      localStorage.setItem(
        "currentSessionInfo",
        JSON.stringify(userInfo?.data)
      );
      setLoading(false);
    }
  }, [loggedInUserStatus, userInfo]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const loginResult = await dispatch(loginUser(data));
      const result = unwrapResult(loginResult);
      if (!result.error) {
        navigate(`/${restaurantId}/restaurant-details`);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <Container className="h-100 ">
        {loading && <Spinner className="loader" animation="grow" />}
        {!loading && (
          <div className="h-100 w-100 d-flex align-items-center">
            <Row className=" w-100 d-flex justify-content-center">
              <Col sm={4}>
                <div className="login-area">
                  <div className="login-form">
                    {error && (
                      <Alert variant="danger">
                        <IoWarningOutline /> <span>{error} </span>
                      </Alert>
                    )}
                    <h2 className="mb-4 text-center">Welcome to Foodini</h2>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <InputGroup className="mb-3">
                        <Form.Control
                          aria-label="Email"
                          aria-describedby="Email"
                          type="email"
                          name="email"
                          {...register("email")}
                          isInvalid={errors.email}
                          placeholder="Email"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <FaUser />
                        </InputGroup.Text>
                        <Form.Control.Feedback id="emailError" type="invalid">
                          {errors.email?.message}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <Form.Control
                          aria-label="password"
                          aria-describedby="password"
                          type="password"
                          name="password"
                          {...register("password")}
                          isInvalid={errors.password}
                          placeholder="Password"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <FaLock />
                        </InputGroup.Text>
                        <Form.Control.Feedback
                          id="passwordError"
                          type="invalid"
                        >
                          {errors.password?.message}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <Button className="mt-2 w-100" type="submit">
                        Login
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Login;
