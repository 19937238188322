import React, { useState } from "react";
import { Form, Row, Col, Button, InputGroup, Container } from "react-bootstrap";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Controller, useFieldArray } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import Substitute from "./Substitute";
import { sortIngredientsFollowKey } from "../../../utilities/index";

export default function Ingredients({
  ingredients,
  control,
  register,
  errors,
  menuIndex,
  getValues,
  itemIngredients,
  setError,
  updateMenuItemOnIngredientsChange,
  ingredientsKey,
  restaurantId
}) {
  const [substituteKey, setSubstituteKey] = useState(new Date().toISOString());
  const [selectedIngredients, setSelectedIngredients] =
    useState(itemIngredients);
  const [selectedSubstitutes, setSelectedSubstitutes] = useState(
    itemIngredients?.map((ing) => ing?.substitutes ?? [])
  );

  const { fields, remove, update, prepend } = useFieldArray({
    name: `section_items[${menuIndex}].item_ingredients`,
    control
  });

  const [listMenu, setListMenu] = useState([]);

  //Add input for new ingredient
  const addNewIngredientInput = () => {
    if (fields.length === 0 || fields[fields.length - 1].ingredient_id) {
      prepend({ substitute_ingredient: {}, substitutes: [] });
    }
  };

  //Add new ingredient
  const addNewIngredient = (index) => {
    const values = getValues();
    const appendedIngredient =
      values.section_items[menuIndex].item_ingredients[index];

    if (
      !appendedIngredient ||
      (appendedIngredient.substitute_ingredient &&
        appendedIngredient.substitute_ingredient.length === 0)
    ) {
      setError(
        `section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`,
        {
          type: "focus",
          message: "Ingredient is a required field"
        },
        { shouldFocus: true }
      );
      return;
    }
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;

    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id && ing.substitute_ingredient?.length !== 0
    );
    ingredientsToBeSaved.unshift(appendedIngredient);
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
  };

  //Function to remove the new ingredient inputs which are not actually saved in the database
  const cancelNewIngredient = (index) => {
    remove(index);
  };

  //Function to remove ingredient from menu item
  const handleRemoveIngredient = (index) => {
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;
    ingredientList.splice(index, 1);
    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id
    );
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
  };

  //Function to extract allergens of the menu item from backend response
  const ingredientAllergenList = (allergens) => {
    return allergens
      ?.filter((i) =>
        ["Allergens", "FODMAP Diets", "Diets"].includes(
          i.allergens_category?.category_name
        )
      )
      .map((a) => a.allergens_name);
  };

  //Add input for new substitute
  const addNewSubstituteInput = (ingredientIndex) => {
    const values = getValues();
    const selectedIngredient =
      values.section_items[menuIndex].item_ingredients[ingredientIndex];
    const substitutes = selectedIngredient?.substitutes
      ? selectedIngredient?.substitutes
      : [];
    if (
      substitutes.length === 0 ||
      Object.keys(substitutes[substitutes.length - 1]).length !== 0
    ) {
      substitutes.push({});
      update(ingredientIndex, {
        ...selectedIngredient,
        substitutes: substitutes
      });

      setSubstituteKey(new Date().toISOString());
    }
  };

  //Function to handle menu item update on ingredients update
  const handleIngredientOnChangeEvent = (index, options, ingredient) => {
    const currentIngredients = selectedIngredients;
    if (ingredient.ingredient_id) {
      currentIngredients[index].substitute_ingredient = options[0];
    } else {
      currentIngredients.splice(index, 0, {
        substitute_ingredient: options[0]
      });
    }

    setSelectedIngredients(currentIngredients);
    if (ingredient.ingredient_id && options.length !== 0) {
      updateMenuItemOnIngredientsChange(
        menuIndex,
        getValues().section_items?.[menuIndex].item_ingredients?.filter(
          (ing) => ing.ingredient_id
        )
      );
    } else if (index === 0) addNewIngredient(index);
  };

  //Function change reorder list menu
  const handleSearch = (query, index) => {
    const keySearch = query.toLowerCase();
    const menus = [...filterOption(ingredients, index)].filter(
      (menu) => !menu.restaurant_id || menu.restaurant_id == restaurantId
    );

    const listSortMenu = menus.filter((ing) =>
      ing?.name?.toLowerCase().includes(keySearch)
    );

    setListMenu(sortIngredientsFollowKey(listSortMenu, keySearch));
  };

  const handleClick = (index) => {
    const menus = filterOption(ingredients, index);
    setListMenu(
      menus.filter(
        (menu) => !menu.restaurant_id || menu.restaurant_id == restaurantId
      )
    );
  };

  const convertIgredientName = (ingredient) => {
    if (ingredient.display_name) {
      return `${ingredient.display_name} (${ingredient.name})`;
    }

    return ingredient.name;
  };

  const filterOption = (list, index) => {
    return [...list].filter((ing) => {
      return (
        ![...fields.map((si) => si?.substitute_ingredient?.name)]
          .filter(
            (name) =>
              !(
                fields[index]?.substitute_ingredient &&
                fields[index]?.substitute_ingredient?.name?.includes(name)
              )
          )
          .includes(ing?.name) &&
        !selectedSubstitutes[index]?.map((sub) => sub.name).includes(ing?.name)
      );
    });
  };

  return (
    <>
      <Form.Group className="mt-3">
        <Form.Label>Ingredients</Form.Label>
        <Container>
          <Row className="add-new-section my-2">
            <Col sm={4} className="d-flex align-items-center p-0">
              <hr />
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <Button
                type="button"
                variant="outline-primary"
                className="add-new-button"
                onClick={() => addNewIngredientInput()}
              >
                <HiOutlinePlusSm />
                Ingredient
              </Button>
            </Col>
            <Col sm={4} className="d-flex align-items-center p-0">
              <hr />
            </Col>
          </Row>
        </Container>
        {fields?.length > 0 &&
          fields.map((ingredient, index) => (
            <div className="mb-3" key={ingredient.id}>
              <Col sm={10} className="w-100">
                <Form.Group>
                  <div className="ingredient-box">
                    <Controller
                      control={control}
                      name={`section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`}
                      {...register(
                        `section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`
                      )}
                      rules={{ required: true }}
                      isInvalid={
                        !!errors.section_items?.[menuIndex]?.item_ingredients?.[
                          index
                        ] &&
                        errors.section_items?.[menuIndex]?.item_ingredients?.[
                          index
                        ]?.substitute_ingredient
                      }
                      render={({
                        field,
                        fieldState: { error, isDirty, isInvalid, isTouched }
                      }) => {
                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <InputGroup className="position-relative">
                                <Typeahead
                                  key={ingredientsKey}
                                  id={`section_items[${menuIndex}].item_ingredients[${index}].id`}
                                  placeholder="Select Ingredient"
                                  labelKey="name"
                                  multiple={false}
                                  onChange={(options) => {
                                    field.onChange(options);
                                    handleIngredientOnChangeEvent(
                                      index,
                                      options,
                                      ingredient
                                    );
                                  }}
                                  renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps}>
                                      {results.map((result, index) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              color: result.is_official
                                                ? "#0747A6"
                                                : "black",
                                              fontWeight: result.is_official
                                                ? "bold"
                                                : "400"
                                            }}
                                            option={result}
                                            position={index}
                                          >
                                            {convertIgredientName(result)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>
                                  )}
                                  defaultInputValue={field?.value?.name}
                                  options={listMenu}
                                  onFocus={() => handleClick(index)}
                                  onInputChange={(query) =>
                                    handleSearch(query, index)
                                  }
                                />
                                <div className="edit-icon">
                                  <BiEditAlt />
                                </div>
                              </InputGroup>
                              <Button
                                className="d-flex align-items-center ms-2"
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  ingredient.ingredient_id
                                    ? handleRemoveIngredient(index)
                                    : cancelNewIngredient(index);
                                }}
                              >
                                <RiDeleteBinLine className="me-2" />
                                Delete
                              </Button>
                              {ingredient.ingredient_id && (
                                <Button
                                  className="d-flex align-items-center ms-2"
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => addNewSubstituteInput(index)}
                                >
                                  <HiOutlinePlusSm className="me-2" />
                                  Substitutes
                                </Button>
                              )}
                            </div>
                            {(isInvalid || isTouched || isDirty || error) && (
                              <div className="invalid-custom-feedback">
                                {
                                  errors?.section_items?.[menuIndex]
                                    ?.item_ingredients?.[index]
                                    ?.substitute_ingredient?.message
                                }
                              </div>
                            )}
                          </>
                        );
                      }}
                    />

                    <div className="ingredients-allergens">
                      {ingredient?.substitute_ingredient?.allergens && (
                        <p>
                          {ingredientAllergenList(
                            ingredient?.substitute_ingredient?.allergens
                          ).join(", ")}
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Substitute
                  restaurantId={restaurantId}
                  getValues={getValues}
                  control={control}
                  register={register}
                  errors={errors}
                  substitutes={ingredient.substitutes}
                  ingredients={ingredients}
                  selectedIngredients={selectedIngredients}
                  menuIndex={menuIndex}
                  ingredientIndex={index}
                  updateMenuItemOnIngredientsChange={
                    updateMenuItemOnIngredientsChange
                  }
                  setError={setError}
                  key={substituteKey}
                  SelectedSubstitutesToCheckForIngredient={
                    setSelectedSubstitutes
                  }
                />
              </Col>
            </div>
          ))}
      </Form.Group>
    </>
  );
}
