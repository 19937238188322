import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useParams } from "react-router-dom";
import history from "../../utilities/history";

const Main = () => {
  const { id } = useParams();
  const restaurantId = id;

  useEffect(() => {
    history.push(`/${restaurantId}/restaurant-details`);
  }, [restaurantId]);
};

export default Main;
