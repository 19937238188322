import * as yup from "yup";

export const SectionFormSchema = yup.object().shape({
  sections: yup.array().of(
    yup.object().shape({
      section_name: yup.string().required("Section Name is a required field")
    })
  )
});

export const MenuFormSchema = yup.object().shape({
  section_items: yup.array().of(
    yup.object().shape({
      item_name: yup.string().required("Menu Item Name is a required field"),
      description: yup.string().nullable(),
      price: yup
        .number("Price should be a valid number")
        .min(0, "Price should be greater than or equal 0")
        .typeError("Price should be a valid number")
        .transform((v, o) => (o === "" ? null : v))
        .nullable(),
      item_ingredients: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          substitute_ingredient: yup.object().nullable(),
          substitutes: yup.array().nullable()
        })
      )
    })
  )
});
